.gridDiv1, .gridDiv2, .gridDiv3 {
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 3rem;
}

.width-flex {
    width: 30vw;
}

.success {
    color: forestgreen;
}

.error {
    color: darkred;
}

a {
    text-decoration: none !important;
}